<template>
  <page-item collection="news" :limit="5" :texts="texts"/>
</template>

<script>
import PageItem from '@/components/items/Page';
export default {
  name: 'NewsPage',
  components: {
    PageItem,
  },
  data() {
    return {
      texts: {
        title: 'Latest news submitted',
        submit: 'Submit news',
        noAuth: 'to submit news.',
        success: 'News submitted successfully.',
        empty: 'No news yet.',
        modalTitle: 'Submit news'
      }
    }
  }
};
</script>
